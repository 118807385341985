* {
  font-family: Rubik, sans-serif;
}

// body:fullscreen {
//   overflow: scroll !important;
// }
// body:-ms-fullscreen {
//   overflow: scroll !important;
// }
// body:-webkit-full-screen {
//   overflow: scroll !important;
// }
// body:-moz-full-screen {
//   overflow: scroll !important;
// }
body {
  position: -webkit-sticky;
  position: sticky;
  overflow-y: overlay;
}

ol li {
  list-style: none;
}

#root .cesium-widget-credits {
  display: inline;
}
#root .cesium-credit-logoContainer {
  display: none !important;
}
#root .cesium-viewer-bottom{
  display: none;
  left: 10px !important;
  bottom: 45px !important;
}
#root .cesium-credit-expand-link {
  display: none !important;
}

#xd-logo {
  color: white;
  font-weight: bolder;
  font-size: 18px;
  margin-right: 6px;
}

.ant-drawer-mask {
  background-color: rgba(0, 0, 0, 0.15);
}

#mainDrawer {
  .ant-drawer-close {
    height: 70px;
  }

  .ant-drawer-title {
    height: 40px;
    line-height: 40px;
  }

  #demo1,
  #demo2 {
    width: 100%;
  }

  .ant-drawer-content {
    overflow: hidden;
  }

  .ant-drawer-wrapper-body {
    position: relative;
  }

  .ant-drawer-body {
    height: calc(100vh - 73px);
    overflow-x: hidden;
    overflow-y: auto;
    padding: 24px 24px 80px;

    .username {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px 140px 10px 24px;
      background-color: #fafafa;
      border-top: 1px solid #e8e8e8;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .sticky {
      position: absolute;
      right: 0;
      bottom: 0;
      background-color: #fafafa;
      border: 1px solid #e8e8e8;
      border-bottom: none;
      border-right: none;
      padding: 10px 24px;

      .anticon {
        margin-right: 5px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.views-drawer {
  height: fit-content !important;
  .ant-drawer-content-wrapper {
    height: auto !important;

    .ant-drawer-content {
      background-color: rgba(38, 38, 38, 0.75);

      .ant-drawer-wrapper-body {
        overflow: hidden;

        .ant-drawer-header {
          border-top: 1px solid #444;
          border-bottom: 1px solid #444;
          background-color: transparent;
        }
      }
    }
  }

  .slick-slider {
    .slick-slide {
      padding: 0 10px;
    }

    .slick-arrow {
      z-index: 1;

      &:before {
        font-size: 40px;
        transition: ease 0.3s;
      }

      &:hover {
        &:before {
          opacity: 1;
          transition: ease 0.3s;
        }
      }

      &.slick-next {
        right: 0;
      }

      &.slick-prev {
        left: -20px;
      }

      &.slick-disabled {
        opacity: 0;
        transition: ease 0.3s;
      }
    }
  }

  .anticon-close {
    color: #fff;
  }
}

.avatar-uploader .startup_image-uploader {
  &.has-image {
    .ant-upload {
      width: auto !important;
      max-width: 250px;
      height: auto !important;

      >span {
        display: block !important;
        padding: 0 !important;
      }
    }
  }
}

#setting-control {
  background-color: rgba(222, 222, 222, 0.75);
  padding: 10px;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 2px;
  overflow: auto;
  height: 97%;

  @media screen and (max-width: 768px) {
    padding: 8px;
  }

  .ant-btn-icon-only {
    margin-left: 5px;
  }

  .ant-cascader-picker,
  .ant-btn-icon-only {
    margin-bottom: 10px;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
}

.custom-ant-dropdown-menu {
  * {
    color: white;
  }

  .menu-wrapper {
    background-color: rgba(38, 38, 38, 0.75);
    padding: 15px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;

    // min-width: 240px;
    &:before {
      display: block;
      content: '';
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom: 10px solid rgba(38, 38, 38, 0.75);
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .menu-title {
    color: white;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
  }

  .ant-collapse {
    border: none;
    background-color: transparent;
    color: white;

    .ant-collapse-item {
      border: 1px solid #545454;
      border-radius: 5px;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      .ant-collapse-header {
        color: white;
        padding: 5px 10px 5px 30px;
        font-size: 13px;

        .ant-collapse-arrow {
          left: 10px;
        }
      }

      .ant-collapse-content {
        background-color: transparent;
        color: white;
        border-top: 1px solid #545454;

        .ant-collapse-content-box {
          @media screen and (max-width: 768px) {
            padding: 10px;
          }
        }
      }
    }
  }
}
.drawerProjectLink{
  .ant-drawer-body {
    padding-top: 24px !important;
  }
}
.pageDetailDrawer {
  .tileset-name {
    font-weight: 500;
    margin-bottom: 5px;
    color: #191919;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 190px;
  }

  .ant-drawer-close {
    height: 100%;
  }

  .ant-drawer-body {
    margin-right: 8px;
    padding-top: 10px;
    padding-left: 15px;
    overflow: hidden;

    .ant-table-wrapper {
      position: relative;
      left: -24px;
      top: -24px;
    }

    .ant-table {
      table {
        width: calc(100% + 48px);
      }
    }

    .ant-table-selection-col,
    .ant-table-selection-column {
      width: 30px;
      padding: 0 0 0 10px !important;
    }

    .ant-table-row-selected {
      td {
        background-color: #e1eded;
      }
    }

    td {
      padding: 0 10px;
    }
  }

  .ant-tree {
    .ant-tree-node-content-wrapper {
      .ant-tree-iconEle {
        width: auto;

        .ant-avatar {
          background: none !important;
          height: auto !important;
          line-height: inherit !important;
        }

        >div>img {
          margin-bottom: 10px;
        }

        .hide {
          svg {
            path {
              fill: rgb(151, 160, 175);
            }
          }
        }
      }

      white-space: nowrap;
    }
  }
}

.cesium-viewer-animationContainer {
  display: none;
}

main.ant-layout-content div.cesium-viewer .cesium-widget canvas {
  position: fixed !important;
}

.cesium-viewer-geocoderContainer {
  // position: fixed;
  // bottom: 10px;
  // left: 5px;
  // .search-results {
  //   bottom: 100%;
  // }
}

.model-setting-dropdown {
  .setting-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    >span {
      margin-right: 10px;
      width: 150px;
      line-height: 1;
    }

    .ant-switch-small .ant-switch-inner {
      font-size: 11px;
    }
  }
}

.ant-popover {
  z-index: 99999;
}

#editModelPanel {
  .ant-form-item {
    margin-bottom: 10px;
  }
}

#calculation-drawer {
  .ant-form-item {
    margin-bottom: 5px;
  }
}

.cesium-viewer-fullscreenContainer {
  width: 37px;
  height: 41px;
  padding: 0px 7px 11px 0;
  position: fixed;
}

.ant-drawer {
  z-index: 9999;
}

.ant-dropdown {
  z-index: 99999;
}

.hidden-tiles-drawer .ant-drawer-body {
  padding: 0;
}

#editSketchPanel {
  z-index: 9999;
}

.ant-calendar-picker-container {
  z-index: 9999;
}

.ant-picker-dropdown {
  z-index: 99999;
}

.ant-select-dropdown {
  z-index: 99999;
}

.ant-message {
  z-index: 999999;
}

.text-c {
  text-align: center;
}

.distance-legend {
  left: 5px;
  bottom: 10px;
  position: fixed;
}

.popover-ar {
  left: auto !important;
  right: 40px;

  .ant-popover-inner-content {
    padding: 0;
  }
}

.help-btn-wrap{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.help-btn-wrap.record-data{
  padding-bottom: 5px;
}

.popover-ar .ant-popover-inner,
.popover-ar-mobile .ant-popover-inner {
  background: none !important;
  box-shadow: none;
}

.popover-ar-mobile {
  .ant-popover-inner-content {
    padding: 0;
  }
}

.popover-ar-vertical {
  .ant-popover-inner-content {
    display: grid;
  }

  .ant-popover-inner-content button:first-child {
    position: relative;
    left: 5px;
  }

  .ant-popover-inner-content button {
    margin-bottom: 3px;
  }
}

.notifile-style {
  .ant-notification-notice-message {
    margin-bottom: 0px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
  }
}

.cursor-pointer {
  &:hover {
    cursor: pointer;
  }

  i {
    margin-right: 5px;
  }
}

.cesium-performanceDisplay-fps {
  color: yellow;
}

.cesium-performanceDisplay-defaultContainer {
  top: 136px;
  right: 21px;
}

@media (max-height: 540px) {
  .cesium-performanceDisplay-defaultContainer {
    top: 136px;
    right: 46px;
  }
}

.cesium-viewer-vrContainer {
  bottom: 140px;
  width: 37px;
  height: 41px;
  padding: 0px 7px 11px 0;
  position: fixed;
  right: 0 !important;
}

// .ant-modal-footer {
//   text-align: left;
// }

#artest-page {
  .ant-layout-header {
    height: 50px;
    line-height: 50px;
    color: #222;
    padding: 0;
    border-bottom: 1px solid #999 !important;
    background: #fff;

    .ant-switch {
      // position: absolute;
      float: right;
      margin-right: 10px;
      margin-top: 15px;
    }
  }
}

#opencv2-control {

  .ant-input-number,
  .ant-switch {
    margin-bottom: 8px;
  }
}

#addAModal-control {
  overflow: auto;
  padding: 0;
}

.addArModal {
  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-footer {
    text-align: right;
  }
}

.ant-tooltip {
  z-index: 9999;
}

#bgTask {
  border: #4e3a3a !important;
  background-color: #4a4a4ab0;
  color: white;
}

.model-topic {
  margin: 15px;
}

.text-right {
  text-align: right;
}

.box-dragable {
  padding: 0 10px 10px 10px;
}

.dragcontainer {
  max-height: 400px;
  display: flex;

  .ant-btn {
    padding: 0 7px;
  }
}

.pr-5 {
  padding-right: 5px;
}

.filterbutton {
  margin: 0 auto;
  float: right;
  margin-right: 0 !important;
}

.splitpanel {
  top: 0;
  left: 0;
  width: 10px;
  bottom: 0;
  cursor: ew-resize;
  padding: 4px 0 0;
  z-index: 100;
  position: absolute;
  border-top: 1px solid #ddd;
  background-color: #f2cfc4;
}

.splitpanel-mobile {
  top: 0;
  left: 0;
  bottom: 0;
  cursor: ew-resize;
  padding: 4px 0 0;
  z-index: 100;
  position: absolute;

  .btnSplitPanel {
    position: absolute;
    top: 50%;
    margin-left: -15px;
    padding: 0 !important;
  }
}

.drawerObjectDetail {
  .ant-drawer-body {
    padding: 20px 0px 20px 5px;
    margin-right: 8px;
  }

  .ant-tree-child-tree>li:first-child {
    padding-top: 0;
  }

  .ant-tree li {
    padding: 0;
  }
}

.pageDetailDrawer,
.drawerObjectDetail,
.wrapAttributeModal {

  .splitpanel,
  .splitpanel-mobile {
    right: 0;
    left: auto;
  }

  .splitpanel-mobile {
    .btnSplitPanel {
      margin-left: -16px;
    }
  }
}

.ant-table-tbody {
  tr {
    td {
      padding: 12px 16px;
    }

    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

      td {
        background-color: #fff !important;
      }
    }
  }
}

.my-modal-class {
  .ant-modal-footer {
    text-align: right !important;
  }
}

.custom-slider {
  margin: 14px 0px 10px;

  .ant-slider-rail {
    height: 10px;
  }

  .ant-slider-track {
    height: 10px;
  }

  .ant-slider-handle {
    width: 20px;
    height: 20px;
  }
}

.css-link-delete {
  color: rgb(246, 77, 10);
  font-weight: 500;
  cursor: pointer;
}

.replyto {
  float: right;
  bottom: 0;
  margin-top: 2px;
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  background-color: #fff;
  color: #f26524;
}

.ant-comment-inner {
  padding: 5px 0 !important;

  .ant-avatar {
    width: 28px;
    height: 28px;
    line-height: 28px;
  }
}

.drawerTopicList {
  .splitpanel-horizontal {
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    cursor: n-resize;
    padding: 4px 0 0;
    z-index: 100;
    position: absolute;
    border-top: 1px solid #ddd;
    background-color: #f2cfc4;
  }

  .splitpanel-horizontal-mobile {
    top: 0;
    left: 50%;
    width: 100%;
    cursor: n-resize;
    padding: 4px 0 0;
    z-index: 100;
    position: absolute;

    .btnSplitPanel {
      position: absolute;
      top: 50%;
      margin-top: -15px;
      padding: 0 !important;
    }
  }

  .toggleFullScreen {
    margin-right: 40px;
    position: absolute;
    right: 0;
    width: 30px;
    height: 56px;
    line-height: 56px;
    text-align: center;
    position: absolute;
    top: 0;
    display: block;
    width: 30px;
    height: 56px;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 700;
    font-size: 16px;
    font-style: normal;
    line-height: 56px;
    text-align: center;
    text-transform: none;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;
    text-rendering: auto;

    &:hover,
    &:focus {
      color: rgba(0, 0, 0, 0.75);
    }
  }

  // .ant-drawer-content-wrapper{
  //   overflow-y: auto;
  //   background-color: #fff;
  // }
}

.link-model-tileset .ant-collapse-content-box {
  word-wrap: break-word;
}

.wrapAttributeModal {
  .ant-modal-header {
    border-bottom: none;

    .ant-modal-title {
      margin-left: -24px;
      margin-right: -14px;
      margin-bottom: -16px;
      margin-top: -16px;
    }
  }

  .ant-modal-footer {
    .ant-btn {
      &.ant-btn-primary {
        display: none;
      }
    }
  }
}

.readOnly {
  pointer-events: none;
}

.custom-ant-dropdownmenu {
  * {
    color: white;
  }

  .menu-wrapper {
    background-color: rgba(38, 38, 38, 0.75);
    padding: 15px;
    border-radius: 5px;
    top: -36px;
    margin-left: 50px;
    position: absolute;

    &:before {
      display: block;
      content: '';
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-width: 10px 15px 10px 0;
      border-color: transparent rgba(38, 38, 38, 0.75) transparent transparent;
      position: absolute;
      left: -15px;
      top: 7px;
    }
  }

  .menu-title {
    color: white;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
  }

  .ant-collapse {
    border: none;
    background-color: transparent;
    color: white;

    .ant-collapse-item {
      border: 1px solid #545454;
      border-radius: 5px;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      .ant-collapse-header {
        color: white;
        padding: 5px 10px 5px 30px;
        font-size: 13px;

        .ant-collapse-arrow {
          left: 10px;
        }
      }

      .ant-collapse-content {
        background-color: transparent;
        color: white;
        border-top: 1px solid #545454;

        .ant-collapse-content-box {
          @media screen and (max-width: 768px) {
            padding: 10px;
          }
        }
      }
    }
  }
}

.model-setting-dropdownmenu {

  .setting-row,
  .setting-custom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }

    >span {
      margin-right: 10px;
      width: 150px;
      line-height: 1;
    }

    .ant-switch-small .ant-switch-inner {
      font-size: 11px;
    }

    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .setting-row {
    margin-bottom: 10px;
  }
}

.cesium-viewer-vrContainer {
  // hide vrbutton for custom
  display: none !important;
}

@media (orientation: portrait) and (hover: none) and (pointer: coarse) {

  .cesium-viewer-geocoderContainer:hover .cesium-geocoder-input,
  .cesium-viewer-geocoderContainer .cesium-geocoder-input:focus,
  .cesium-viewer-geocoderContainer .cesium-geocoder-input-wide {
    width: 155px !important;
  }
}

// located on data-attribute button
.cesium-viewer-geocoderContainer {
  z-index: 999; // higher data-attribute button

  .cesium-geocoder-input {
    background-color: rgba(40, 40, 40, 1);
  }
}

// custom css for compass on mobile landscape
.compass {
  right: 8px !important;
}

@media (orientation: landscape) {
  .compass {
    right: 35px !important;
  }
}

.projectDashBoardPage {
  .ant-drawer-content {
    overflow: visible;
  }

  .split-panel-mobile {
    span {
      padding-top: 3px !important;
    }
  }

  .split-panel {
    top: 0;
    left: 0;
    width: 10px;
    bottom: 0;
    cursor: ew-resize;
    padding: 4px 0 0;
    z-index: 100;
    position: absolute;
    border-top: 1px solid #ddd;
    background-color: #f2cfc4;
  }

  .chart-container {
    background-color: #fff;
    width: 100%;

    .contain-left {
      .topicChart-wraper {
        .topic-status-option {
          padding: 0 40px;

          .topic-title {
            color: #acaeb4;
            font-size: 20px;
          }
        }

        .topic-chart {
          .xLegendSymbol {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: 5px solid;
            border-radius: 30px;
            height: 30px;
            width: 30px;
            margin-right: 10px;
            font-size: 11px;
          }

          .highcharts-legend-item {
            rect {
              visibility: hidden;
            }
          }

          .highcharts-legend-item.highcharts-pie-series> :first-child {
            width: 160px;
          }
        }
      }

      .topicType-wraper {
        .topic-title {
          color: #acaeb4;
          font-size: 20px;
          padding: 0 40px;
        }

        .topic-container {
          margin-top: 30px;
        }
      }

      .timeline-chart-wraper {
        .timeline-chart {
          width: 100%;

          .highcharts-legend-item {
            path {
              visibility: hidden;
            }
          }

          .xLegendSymbol {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: 1px solid;
            height: 15px;
            width: 30px;
            margin-right: 10px;
            font-size: 11px;
          }
        }
      }
    }

    .contain-right {
      .contain-right-wraper {
        padding: 0 20px;
        width: 100%;

        .workflow-wraper {
          width: 100%;

          .workflow-title {
            color: #acaeb4;
            font-size: 20px;
          }

          .workflow-control {
            width: 100%;
            padding: 20px;
          }
        }

        .workflow-chart-container {
          margin-top: 30px;
          width: 100%;

          .ant-col {
            width: 100%;
          }
        }
      }

      .project-complete-wraper {
        padding: 0 20px;
        width: 100%;
        margin-top: 40px;

        .title {
          font-size: 20px;
          color: #acaeb4;
        }

        .container-chart {
          margin-top: 30px;
          width: 100%;

          .project-complete {
            width: 100%;

            .highcharts-container {
              max-height: 340px;
              height: 100%;
            }
          }
        }
      }
    }
  }

  .dotted {
    display: inline-block;
    width: 30px;
    height: 10px;
    border-radius: 10px;
    margin-right: 5px;
  }

  .dotted.modeling {
    background-color: #55d8fe;
  }

  .dotted.internal {
    background-color: #ff8373;
  }

  .dotted.customer {
    background-color: #ffda83;
  }

  .dotted.construction {
    background-color: #a3a0fb;
  }

  .topic-detail {
    width: 100%;
    max-width: 200px;
    display: flex;
    justify-content: space-between;
  }

  .label-wrap,
  .count-topic {
    display: flex;
    align-items: center;
  }

  .count-topic {
    color: #ddd;
  }

  @media screen and (max-width: 768px) {

    .title_txt,
    .title_name {
      font-size: 20px;
    }
  }
}

#projectDashBoardPage[max-width~='575px'] {
  .topdashboard-item {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .contain-left {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;

    .timeline-chart {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .contain-right {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .topicChart-wraper {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .topicType-wraper {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .project-complete .highcharts-container {
    max-height: 240px;
    height: 100%;
  }

  .title_txt,
  .title_name {
    flex-wrap: wrap;
    font-size: 18px;
    width: 100%;
  }
}

#projectDashBoardPage[min-width~='576px'] {
  .topdashboard-item {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .contain-left {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;

    .timeline-chart {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .contain-right {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .topicChart-wraper {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .topicType-wraper {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .project-complete .highcharts-container {
    max-height: 240px;
    height: 100%;
  }
}

#projectDashBoardPage[min-width~='768px'] {
  .topdashboard-item {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .contain-left {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .contain-right {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

#projectDashBoardPage[min-width~='992px'] {
  .topdashboard-item {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .contain-left {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .contain-right {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

#projectDashBoardPage[min-width~='1200px'] {
  .topdashboard-item {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .contain-left {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .contain-right {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .topicChart-wraper {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .topicType-wraper {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

#projectDashBoardPage[min-width~='1600px'] {
  .topdashboard-item {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .contain-left {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .contain-right {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .topicChart-wraper {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .topicType-wraper {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.ant-avatar.ant-avatar-icon {
  font-size: 20px;
}

.custom-wraper-splitPanel {
  z-index: 9999;

  .ant-drawer-content {
    overflow: visible;
  }

  .btnSplitPanel {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      padding-top: 3px !important;
    }
  }
}

.ant-tree {
  margin-right: -22px;

  .ant-tree-list-holder-inner {
    align-items: stretch;

    .ant-tree-node-content-wrapper {
      flex: auto;

      .ant-tree-title {
        line-height: 24px;
        margin-left: 5px;
      }
    }
  }
}

.modal-organization {
  .ant-modal-footer {
    text-align: right;
  }

  .ant-row.ant-form-item {
    margin-bottom: 10px;
  }

  .ant-row {
    display: block;
  }

  //Hide input arrow
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .ant-upload.ant-upload-drag {
    width: 150px;
    min-height: 150px;
    margin-right: 8px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
  }

  .ant-upload.ant-upload-drag .ant-upload-btn {
    display: table;
    height: 100%;
    min-height: 150px;
  }
}

.ant-image-preview-wrap {
  z-index: 9999;
}

.ant-tooltip {
  z-index: 99999;
}

.ant-tabs-dropdown {
  z-index: 999999;
}

.modal-send-email-notification .ant-modal-body {
  padding: 24px 24px 0 24px;
}

.modal-send-email-notification .ant-form-item {
  margin-bottom: 16px;
}

.cookieWraper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9998;
  background-color: rgba(0, 0, 0, 0.5);
}

.terms-conditions .ant-modal-close {
  display: none;
}

.argui-icon {
  position: absolute;
  top: -1px;
  left: -1px;
}

.element-library {
  .ant-tree-iconEle {
    display: inline-flex !important;
    width: auto !important;

    .ant-avatar {
      background: none !important;
      height: auto !important;
      line-height: inherit !important;
    }

    .hide {
      svg {
        path {
          fill: rgb(151, 160, 175);
        }
      }
    }
  }
}

#editSketchPanel .ant-tree-checkbox-disabled {
  cursor: not-allowed;
  display: none;
}

.ant-popover-inner-content {
  padding: 5px;
}

.antdModalProcessUploadFile , .antdModalProcessWaittingUploadFile {
  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-wrap {
    position: inherit;
  }
}

.modal-container .ant-modal-wrap {
  position: inherit;
}
.ant-modal-wrap {
    z-index: 9999
}
.sketchIcon {
  background: WHITE;
  width: 30px;
  height: 30px;
  border-radius: 5px;
}
.sketchIcon:hover {
  background-color: #e7e7e7;
}
.sketchIcon svg {
  width: 100%;
  height: 100%;
}


.selectedNode-img {
  width: 150px;
  height: 150px;
}



#editSketchPanel {
  overflow: auto;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

#editSketchPanel::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.testIcon {
  border: 1px solid black;
}

.ant-drawer-close {
  margin-right: 12px;
  position: absolute;
  right: 0;
}

.polygon-portal {
  position: absolute;
  z-index: 100;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);

  .portal-wrapper {
    filter: drop-shadow(0px 12px 24px rgba(49, 55, 64, 0.11));
    border-radius: 4px;
    padding: 10px 20px;
    max-height: calc(100vh - 20px);
    background: #ffffff;

    .btn-update-polygon {
      margin-left: 5px;
    }
  }
}

.ant-btn-default-blue {
  color: #fff !important;
  border-color: #1890ff !important;
  background: #1890ff !important;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px #0000000b;
}

.ant-btn-default-blue:hover,
.ant-btn-default-blue:focus {
  color: #fff !important;
  border-color: #40a9ff !important;
  background: #40a9ff !important;
}

.ant-message {
  z-index: 999999999999;
}

.btn-info {
  background: #54C0E8;
  border-color: #54C0E8;
  color: #FFF;
}
  .btn-info:hover,
  .btn-info:focus {
      background: #38A5CE;
      border-color: #38A5CE;
      color: #FFF;
  }
.img-xdtwin-Guide{
  max-height: 750px;
}

.ant-notification {
  z-index: 999999;
}

.organization-body {
  padding: 15px 30px;
}


.form-horizontal {
  display: flex;
  flex-direction: row !important;

  .ant-form-item-label {
    display: flex;
    align-items: center;
    padding: 0;
    margin-right: 5px;
  }
}

.form-vertical {
  display: block !important;
}

.custom-input-color {
  width: '93px';
  height: '33px';
}

.draggable-tree .ant-tree-draggable-icon {
  display: none;
}

.upload-image-organization .ant-upload.ant-upload-drag .ant-upload-drag-container {
  height: 150px;
}

.list-item-organization {
  width: 100%;
  padding: 0 10px;
  margin-bottom: 5px;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}

.input-upload-image-square {
  width: 150px;
  min-height: 150px;
  max-height: 150px;
}

.input-upload-image-square .ant-upload.ant-upload-select-picture-card {
  width: 150px;
  min-height: 150px;
  max-height: 150px;
}

.input-upload-image-project {
  width: 250px;
  min-height: 175px;
  max-height: 175px;
}

.input-upload-image-project .ant-upload.ant-upload-select-picture-card {
  width: 250px;
  min-height: 175px;
  max-height: 175px;
}

.trim-word-accorddingly {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-size-select {
  font-size: 11px;
}

.image-user-header-top {
  border-color: #d1d1f0;
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.icon-down-header {
  font-size: 12px !important;
  line-height: 18px;
  margin: 0px 0px 0px 4px;
  text-align: left;
  vertical-align: -3px !important;
}

.button-down-header {
  border: none;
  outline: none;
  background: none;
  color: black;
  flex-grow: 1px;
  height: 100%;
}

#generic-export-button {
    margin-left: 5px;
    background: #F26524;
    outline: none;
    border: 1px solid #d9d9d9;
    padding: 4px 15px;
    font-size: 14px;
    touch-action: manipulation;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    color :#fff;
    white-space: nowrap;
    text-align: center;
}








/*
    css polygonDraw initial twipsy code taken from bootstrap
*/
.twipsy {
  display: block;
  position: absolute;
  visibility: visible;
  max-width: 200px;
  min-width: 100px;
  font-size: 11px;
  z-index: 1000;
  opacity: 0.8;
  -khtml-opacity: 0.8;
  -moz-opacity: 0.8;
  filter: alpha(opacity=80);
}
.twipsy.left .twipsy-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #000000;
}
.twipsy.right .twipsy-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}
.twipsy-inner {
  background-color: #000000;
  color: white;
  text-align: center;
  max-width: 200px;
  text-decoration: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.twipsy-arrow {
  position: absolute;
  width: 0;
  height: 0;
}
.ml-8 {
  margin-left: 8px;
}

/*
css rules for the infowindow
*/

.infoWindow {
  position: absolute;
  min-width: 100px;
  max-width: 300px;
}
.infoWindow #frame {
  padding: 10px;
  border: 1px solid black;
  background: white;
}
.infoWindow #close {
  float: right;
  margin: 5px 2px;
  font-size: small;
  color: gray;
  cursor: pointer;
}
.infoWindow #arrow {
  position: absolute;
  bottom: -8px;
  left: 50%;
  margin-left: -10px;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-top: 10px solid white;
}
.disableForm {
    background-color: rgb(229, 229, 229);
    border-color: rgb(229, 229, 229);
    pointer-events:none;
    .ant-collapse-content{
      background-color: rgb(229, 229, 229);
      border-color: rgb(229, 229, 229);
    }
}

#edit-custom-attribute{
  .ant-form-item{
    margin-bottom : 12px ;
  }
}

.custom-notivication {
  width: fit-content !important;
}
.custom-notivication.rebuild-modal .ant-notification-notice-close {
  display: none;
}
.custom-notivication .ant-notification-notice-description {
  margin-left: 8px !important;
  max-height: calc(100vh - 200px);;
  overflow: auto;
 }

 .ant-modal-body-12{
  .ant-modal-body{
    padding : 12px;
    max-height: 500px;
    overflow-y: auto;
  }
 }

 .d-none {
  display: none;
}
.display-none-tree-node {
  display: none !important;
}

.help-btn-wrapper{
  display: flex;
  gap: 10px;
  align-items: center;
}

.cookie-consent-conatiner{
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
  top: 20px !important;;
  max-width: 50% !important;;
  left: 25% !important;;
  padding: 16px 16px 0 16px !important;;
}
.cookie-text-capitalize{
  flex : 0 !important;
  margin: 10px 0 0 0 !important;
}

.pw-custom{
  -webkit-text-security: disc;
}

.pw-custom::placeholder {
  -webkit-text-security: none;
}

.ant-drawer.re-order-has-cookie {
  z-index: 9996 !important;
}

.flex-center-global {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dhx_message-container{
  z-index: 9998;
}

.cus-drawer-trans {
  .ant-drawer-content-wrapper {
    .ant-drawer-content {
      background-color: rgba(38, 38, 38, 0.75);

      .ant-drawer-wrapper-body {
        overflow: hidden;

        .ant-drawer-header {
          border-top: 1px solid #444;
          border-bottom: 1px solid #444;
          background-color: transparent;
          .anticon-close {
            color: #fff;
          }
        }
      }
    }
  }
}

.rule--value, .rule--field, .rule--operator, .rule--value {
  margin-bottom: 10px;
}
.rule.group-or-rule {
  padding-bottom: 0 !important;
}
@primary-color: #F26524;@link-color: #1da57a;